export default class Timer {
    timer = null
    duration = 0
    remainingSeconds = 0
    elapsedTime = 0

    constructor(duration) {
        this.duration = duration
        this.remainingSeconds = this.duration
    }

    start() {
        this.timer = setInterval(() => {
            this.tick()
        }, 1000)
    }

    stop() {
        clearInterval(this.timer)
        this.elapsedTime = 0
        this.remainingSeconds = this.duration
    }

    resetTimer() {
        clearInterval(this.timer)
        this.elapsedTime = 0
        this.remainingSeconds = this.duration
        this.start()
    }

    tick() {
        this.elapsedTime++
        this.remainingSeconds--
        if (this.elapsedTime >= this.duration) {
            clearInterval(this.timer)
        }
        this.remainingSeconds = Math.max(0, this.remainingSeconds)
    }

    getMinutes() {
        return String(Math.floor(this.remainingSeconds / 60)).padStart(2, '0')
    }

    getSeconds() {
        return String(Math.floor(this.remainingSeconds % 60)).padStart(2, '0')
    }

    getRemainingSeconds() {
        return this.remainingSeconds
    }
}
