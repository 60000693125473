import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomeEmRoute',
        component: () => import('@/components/routes/ellinikaMarket/HomeEmRoute')
    },
    {
        path: '/em-scan-card',
        name: 'ScanCardEmRoute',
        component: () => import('@/components/routes/ellinikaMarket/ScanCardEmRoute')
    },
    {
        path: '/em-phone-input',
        name: 'PhoneInputEmRoute',
        component: () => import('@/components/routes/ellinikaMarket/PhoneInputEmRoute')
    },
    {
        path: '/em-otp',
        name: 'OtpEmRoute',
        component: () => import('@/components/routes/ellinikaMarket/OtpEmRoute')
    },
    {
        path: '/em-find-merge',
        name: 'FindMergeEmRoute',
        component: () => import('@/components/routes/ellinikaMarket/FindMergeEmRoute')
    },
    {
        path: '/em-customer-details',
        name: 'CustomerDetailsEmRoute',
        component: () => import('@/components/routes/ellinikaMarket/CustomerDetailsEmRoute')
    },
    {
        path: '/em-gdpr',
        name: 'GdprEmRoute',
        component: () => import('@/components/routes/ellinikaMarket/GdprEmRoute')
    },
    {
        path: '/home',
        name: 'ScanCardRoute',
        component: () => import('@/components/routes/ScanCardRoute')
    },
    {
        path: '/phone-input',
        name: 'Phone Input',
        component: () => import('@/components/routes/PhoneInputRoute')
    },
    {
        path: '/otp',
        name: 'Otp Route',
        component: () => import('@/components/routes/OtpRoute')
    },
    {
        path: '/customer-details',
        name: 'Customer Details',
        component: () => import('@/components/routes/CustomerDetailsRoute')
    },
    {
        path: '/gdpr',
        name: 'Gdpr Route',
        component: () => import('@/components/routes/GdprRoute')
    },
    {
        path: '/email-verification',
        name: 'Email Verifivation Route',
        component: () => import('@/components/routes/EmailVerificationRoute')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
})

const fetchCurrentCustomerAndGoTo = (next) => {
    if (!store.getters.getCurrentCustomer && store.getters.getToken) {
        store.dispatch('refreshCurrentCustomer').then(() => {
            next()
        })
    } else {
        next()
    }
}

router.beforeEach((to, from, next) => {
    if ((to.path === '/gdpr' || to.path === '/customer-details') && !localStorage.getItem('api-token')) {
        next('/')
    } else if (from.path === '/home' && to.path === '/phone-input' && to.query.existingCustomer) {
        next(false)
    } else if (to.path === '/otp' && (from.path === '/gdpr' || from.path === '/customer-details')) {
        next(false)
    } else if (from.path === '/home' && to.path === '/otp' && !localStorage.getItem('barcode')) {
        next(false)
    } else {
        fetchCurrentCustomerAndGoTo(next)
    }
})

export default router
