import restAPI from '@/utils/rest-api'
export default {
    findByCard(barcode) {
        return restAPI({
            url: '/customers/find-by-card',
            method: 'post',
            data: { barcode }
        })
    }
}
