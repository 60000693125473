import customerService from '@/services/customer-service'
import jwtUtils from '@/utils/jwt-utils'
export default {
    state: {
        token: localStorage.getItem('api-token'),
        barcode: localStorage.getItem('barcode'),
        encryptedBarcode: localStorage.getItem('encrypted-barcode'),
        otpExpirationTime: localStorage.getItem('otp-expiration-time'),
        phoneNumber: localStorage.getItem('phone-number'),
        obfuscatedMergedCards: JSON.parse(localStorage.getItem('obfuscated-merged-cards')) || [],
        totalMergedCardPoints: JSON.parse(localStorage.getItem('total-merged-card-points')),
        currentCustomer: null
    },
    mutations: {
        setToken(state, apiToken) {
            state.token = apiToken
        },
        setBarcode(state, barcode) {
            state.barcode = barcode
        },
        setEncryptedBarcode(state, encryptedBarcode) {
            state.encryptedBarcode = encryptedBarcode
        },
        setOtpExpirationTime(state, otpExpirationTime) {
            state.otpExpirationTime = otpExpirationTime
        },
        setPhoneNumber(state, phoneNumber) {
            state.phoneNumber = phoneNumber
        },
        setObfuscatedMergedCards(state, obfuscatedMergedCards) {
            state.obfuscatedMergedCards = obfuscatedMergedCards
        },
        setTotalMergedCardPoints(state, totalMergedCardPoints) {
            state.totalMergedCardPoints = totalMergedCardPoints
        },
        setCurrentCustomer(state, currentCustomer) {
            state.currentCustomer = currentCustomer
        }
    },
    actions: {
        refreshCurrentCustomer({ commit, dispatch }) {
            const authToken = localStorage.getItem('api-token')
            if (authToken && jwtUtils.isJwtExpired(authToken)) {
                dispatch('logout')
            }
            return customerService.getCurrentCustomer().then((customer) => {
                commit('setCurrentCustomer', customer)
            }).catch(() => {
                dispatch('logout')
            })
        },
        login({ commit }, apiToken) {
            localStorage.setItem('api-token', apiToken)
            commit('setToken', apiToken)
        },
        logout({ commit }) {
            commit('setToken', null)
            commit('setBarcode', null)
            commit('setEncryptedBarcode', null)
            commit('setPhoneNumber', null)
            commit('setObfuscatedMergedCards', [])
            commit('setTotalMergedCardPoints', 0)
            commit('setCurrentCustomer', null)
            localStorage.removeItem('barcode')
            localStorage.removeItem('phone-number')
            localStorage.removeItem('encrypted-barcode')
            localStorage.removeItem('api-token')
            localStorage.removeItem('obfuscated-merged-cards')
            localStorage.removeItem('total-merged-card-points')
        },
        setBarcodePair({ commit }, { barcode, encryptedBarcode }) {
            commit('setBarcode', barcode)
            commit('setEncryptedBarcode', encryptedBarcode)
            localStorage.setItem('barcode', barcode)
            localStorage.setItem('encrypted-barcode', encryptedBarcode)
        },
        setOtpExpirationTime({ commit }, otpExpirationTime) {
            commit('setOtpExpirationTime', otpExpirationTime)
            localStorage.setItem('otp-expiration-time', otpExpirationTime)
        },
        setPhoneNumber({ commit }, phoneNumber) {
            commit('setPhoneNumber', phoneNumber)
            localStorage.setItem('phone-number', phoneNumber)
        },
        setCurrentCustomer({ commit }, currentCustomer) {
            commit('setCurrentCustomer', currentCustomer)
        },
        removeOtpExpirationTime({ commit }) {
            commit('setOtpExpirationTime', null)
            localStorage.removeItem('otp-expiration-time')
        },
        setMergedCards({ commit }, mergedCards) {
            commit('setObfuscatedMergedCards', mergedCards.obfuscatedMergedCards)
            commit('setTotalMergedCardPoints', mergedCards.totalMergedCardPoints)
            localStorage.setItem('obfuscated-merged-cards', JSON.stringify(mergedCards.obfuscatedMergedCards))
            localStorage.setItem('total-merged-card-points', JSON.stringify(mergedCards.totalMergedCardPoints))
        }
    },
    getters: {
        getToken: state => state.token,
        getBarcode: state => state.barcode,
        getEncryptedBarcode: state => state.encryptedBarcode,
        isLoggedIn(state) {
            return state.token !== null
        },
        getOtpExpirationTime(state) {
            return state.otpExpirationTime
        },
        getPhoneNumber: state => state.phoneNumber,
        getCurrentCustomer: state => state.currentCustomer,
        getObfuscatedMergedCards: state => state.obfuscatedMergedCards,
        getTotalMergedCardPoints: state => state.totalMergedCardPoints
    }
}
