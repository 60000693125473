import restAPI from '@/utils/rest-api'
export default {
    loginCustomer(username, password) {
        return restAPI({
            url: '/customers/login',
            method: 'post',
            data: { username, password }
        })
    },
    updateCustomerInfo(name, lastname, email) {
        return restAPI({
            url: '/customers/current/update-customer-info',
            method: 'post',
            data: { name, lastname, email }
        })
    },
    mergeByMobile() {
        return restAPI({
            url: '/customers/current/merge-by-mobile',
            method: 'post'
        })
    },
    mergeByEmail() {
        return restAPI({
            url: '/customers/current/merge-by-email',
            method: 'post'
        })
    },
    getCurrentCustomer() {
        return restAPI({
            url: '/customers/current',
            method: 'get'
        })
    },
    updateCustomerEmail(token) {
        return restAPI({
            url: '/customers/current/update-email',
            method: 'post',
            data: { content: token }
        })
    },
    updateGdpr(gdprPreferences) {
        return restAPI({
            url: '/customers/current/update-gdpr',
            method: 'post',
            data: gdprPreferences
        })
    }

}
