<template>
    <div>
        <div class="route">
            <top-bar />
            <div class="form-container">
                <form class="form" novalidate @submit.prevent="submitCard">
                    <h1 class="form-title">{{ $t('EllinikaMarket.header') }}</h1>
                    <div class="form-subtitle">
                        {{ $t('EllinikaMarket.cardQuestion') }}
                    </div>
                    <div class="submit-container">
                        <button type="submit" class="submit" @click="goToScanCardEm()">{{ $t('EllinikaMarket.positiveAnswer') }}</button>
                        <button type="submit" class="submit" @click="goToHome()">{{ $t('EllinikaMarket.negativeAnswer') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import TopBar from '@/components/TopBar'

    export default {
        name: 'HomeEmRoute',
        components: { TopBar },
        data() {
            return {
            }
        },
        created() {
            this.$store.dispatch('logout')
        },
        methods: {
            goToHome() {
                this.$router.push({ path: '/home' })
            },
            goToScanCardEm() {
                this.$router.push({ path: '/em-scan-card' })
            }
        }
    }
</script>

<style scoped>
.route {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #1e5f93 0%, #011f4c 100%);
}

.form-container {
    position: relative;
    margin: 2% auto 1rem;
    width: 90%;
    max-width: 750px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 5px 5px 15px 5px #011f4c;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    position: relative;
}
.form-title {
    color: #00467d;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 0;
}

.form-subtitle {
    margin-top: 50px;
    color: #00467d;
    font-family: 'RobotoBold';
    font-size: 1rem;
}

.submit-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
}

.submit {
    width: 30%;
    font-family: 'RobotoBold';
    letter-spacing: 1px;
    max-width: 150px;
    padding: 0.5rem 0;
    margin: 1rem auto;
    text-align: center;
    border-radius: 3px;
    border: none;
    transition: all 0.15s ease-in-out;
    background-color: #00467d;
    color: #fff;
}

.submit:hover {
    background-color: #025799;
}

.submit:active,
.scan-card-btn:active {
    transform: scale(0.95);
}

@media screen and (min-width: 700px) {
    .form-container {
        margin: 3rem auto 5%;
    }
    .form-title,
    .form-subtitle {
        text-align: center;
    }
}

@media screen and (min-width: 900px) {
    .form-container {
        margin: 3rem auto 5%;
    }
    .form {
        width: 90%;
        margin: 0 auto;
        padding: 3rem;
    }
    .form-title {
        font-size: 2rem;
    }
    .form-subtitle {
        font-size: 1.1rem;
    }
    .submit {
        font-size: 1.2rem;
        max-width: 300px;
        padding: 0.7rem 0;
    }
}

</style>
