export default function isValidEan13(barcode) {
    barcode = String(barcode)
    const number = barcode.slice(0, -1)

    const res = number.substr(0, 12).split('').map((n) => +n).reduce((sum, a, idx) => (
        idx % 2 ? sum + a * 3 : sum + a
    ), 0)

    return parseInt(barcode[12]) === (10 - (res % 10)) % 10
}
