<template>
    <div>
        <div class="route">
            <top-bar />
            <div v-if="currentCustomer && !shouldSkipGdpr()" class="form-container">
                <form class="form" @submit.prevent="submitGdpr">
                    <h1 class="form-title">{{ $t('GdprRoute.consent') }}</h1>
                    <div class="preferences-container">
                        <div
                            v-for="(value, propertyName) in filteredGdpr"
                            :key="propertyName"
                            class="checkbox"
                        >
                            <div v-if="filteredGdpr[propertyName]">
                                <input
                                    :id="propertyName"
                                    v-model="gdpr[propertyName].preference"
                                    type="checkbox"
                                >
                                <label
                                    :for="propertyName"
                                >
                                    <span>
                                        {{ value.text }}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="submit-container">
                        <pulse-loader v-if="loadingCompleted" color="#00467d" size="1rem" class="loader" />
                        <button v-else type="submit" class="submit">{{ $t('GdprRoute.submit') }}</button>
                    </div>
                </form>
            </div>
        </div>
        <custom-modal
            :open="isModalVisible"
            @close="closeModalAndRedirect"
        >
            <template #body>
                <div v-if="popupMessage">
                    <p>{{ popupMessage }}</p>
                    <div
                        v-if="countMergedCards()>0"
                        class="merged-message"
                    >
                        <img src="@/assets/white_info_icon.png" alt="" class="mb-3">
                        <p v-if="countMergedCards()>1" class="mb-0">
                            Βρέθηκαν {{ countMergedCards() }} κάρτες με συνολικά {{ totalMergedCardPoints }} πόντους,
                            οι κάρτες {{ joinMergedCards() }} καταργήθηκαν
                            και μεταφέρθηκαν στη νέα {{ currentCustomer.obfuscatedCardBarcode }}
                            με νέο σύνολο πόντων {{ calcNewTotalPoints() }}.
                        </p>
                        <p v-else class="mb-0">
                            Βρέθηκε {{ countMergedCards() }} κάρτα με {{ totalMergedCardPoints }} πόντους,
                            η κάρτα {{ joinMergedCards() }} μεταφέρθηκε και ενσωματώθηκε
                            στη νέα {{ currentCustomer.obfuscatedCardBarcode }} με νέο σύνολο πόντων {{ calcNewTotalPoints() }}.
                        </p>
                    </div>
                </div>
            </template>
        </custom-modal>
    </div>
</template>

<script>
    import customerService from '@/services/customer-service'
    import TopBar from '@/components/TopBar'
    import CustomModal from '@/components/CustomModal'
    import config from '@/mixins/config'

    export default {
        name: 'GdprRoute',
        components: { TopBar, CustomModal },
        mixins: [config],
        data() {
            return {
                gdpr: {
                    gdprSms: { preference: true, text: 'Για την αποστολή ενημερωτικού υλικού μέσω VIBER/SMS' },
                    gdprTelephone: { preference: true, text: 'Για την επικοινωνία μέσω τηλεφώνου' },
                    gdprEmail: { preference: true, text: 'Για την αποστολή ενημερωτικού και προωθητικού υλικού μέσω email' },
                    gdprProfile: { preference: true, text: 'Για την κατάρτιση προφίλ με σκοπό την εξατομίκευση υπηρεσιών και προϊόντων και για στατιστικούς σκοπούς' }
                },
                isModalVisible: false,
                popupMessage: null,
                loadingCompleted: false
            }
        },
        computed: {
            currentCustomer() {
                return this.$store.getters.getCurrentCustomer
            },
            filteredGdpr() {
                const filteredGdpr = { ...this.gdpr }
                for (const propName in this.gdpr) {
                    const storedPreference = this.currentCustomer[propName]
                    if (storedPreference) {
                        delete filteredGdpr[propName]
                    }
                }
                return filteredGdpr
            },
            obfuscatedMergedCards() {
                return this.$store.getters.getObfuscatedMergedCards
            },
            totalMergedCardPoints() {
                return this.$store.getters.getTotalMergedCardPoints
            }
        },
        created() {
            this.removeApprovedGdprOptions()

            if (this.shouldSkipGdpr()) {
                this.popupMessage = this.$t('GdprRoute.successfulUpdate', { obfuscatedBarcode: this.currentCustomer.obfuscatedCardBarcode })
                this.showModal()
                customerService.updateGdpr(this.getCurrentPreferences())
            }
        },
        methods: {
            removeApprovedGdprOptions() {
                if (this.currentCustomer) {
                    for (const propName in this.gdpr) {
                        this.gdpr[propName].preference = this.currentCustomer[propName]
                    }
                }
            },
            submitGdpr() {
                const gdprPreferences = this.getCurrentPreferences()
                this.loadingCompleted = true
                customerService.updateGdpr(gdprPreferences).then(() => {
                    this.loadingCompleted = false
                    if (this.$route.query.existingCustomer) {
                        this.popupMessage = this.$t('GdprRoute.successfulUpdate', { obfuscatedBarcode: this.currentCustomer.obfuscatedCardBarcode })
                        this.showModal()
                    } else {
                        this.popupMessage = this.$t('GdprRoute.successfulVerification', { obfuscatedBarcode: this.currentCustomer.obfuscatedCardBarcode })
                        this.showModal()
                    }
                    this.$store.dispatch('refreshCurrentCustomer')
                }).catch(() => {
                    this.makeToast('Update Gdpr Failed')
                })
            },
            getCurrentPreferences() {
                const gdprPreferences = {}
                for (const gdprKey of Object.keys(this.gdpr)) {
                    gdprPreferences[gdprKey] = this.gdpr[gdprKey].preference
                }
                return gdprPreferences
            },
            shouldSkipGdpr() {
                return Object.keys(this.filteredGdpr).length === 0
            },
            redirectToKritikos() {
                this.$store.dispatch('logout')
                if (this.config.env === 'development') {
                    this.$router.push('/home')
                } else {
                    this.$router.push('/home')
                    window.location.href = 'https://www.kritikos-sm.gr/'
                }
            },
            showModal() {
                this.isModalVisible = true
            },
            closeModalAndRedirect() {
                this.isModalVisible = false
                this.redirectToKritikos()
            },
            countMergedCards() {
                return this.obfuscatedMergedCards.length
            },
            joinMergedCards() {
                return this.obfuscatedMergedCards.join(', ')
            },
            calcNewTotalPoints() {
                return this.totalMergedCardPoints + this.currentCustomer.points
            }
        }
    }
</script>

<style scoped>
.route {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #1e5f93 0%, #011f4c 100%);
}

.form-container {
    position: relative;
    margin: 2% auto 5%;
    width: 90%;
    max-width: 750px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 5px 5px 15px 5px #011f4c;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    position: relative;
}

.form-title {
    color: #00467d;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 0;
}
.info-message {
    margin-top: 1rem;
    color: #00467d;
    padding: 1rem;
    text-align: center;
    border: 2px solid  #1d5d91;
    border-radius: 3px;
}
.info-message p {
    text-align: center;
}
.form-subtitle {
    color: #00467d;
    font-size: 0.9rem;
}

.checkbox {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: block;
    padding: 3rem 0;
    border-bottom: 1px solid #c2c2c2;
}

.checkbox input[type="checkbox"] {
    width: auto;
    opacity: 0;
    position: absolute;
    left: 0;
    margin-left: -20px;
}
.checkbox label {
    position: relative;
    min-height: 34px;
    display: block;
    padding-left: 50px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
}
.checkbox label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 20px;
    height: 20px;
    transition: transform 0.28s ease;
    border: 1px solid #00467d;
    box-shadow: 3px 3px 0px -1px #00467d;
}
.checkbox label:after {
    content: '';
    display: block;
    width: 10px;
    height: 5px;
    border-bottom: 2px solid #00467d;
    border-left: 2px solid #00467d;
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.25s;
    will-change: transform;
    position: absolute;
    top: 10px;
    left: 10px;
}
.checkbox input[type="checkbox"]:checked ~ label::before {
    color: #00467d;
}

.checkbox input[type="checkbox"]:checked ~ label::after {
    transform: rotate(-45deg) scale(1);
}

.checkbox label span {
    position: absolute;
    top: 41%;
    transform: translateY(-50%);
    color: #00467d;
}
.checkbox:last-child label span {
    top: 50%;
}
.checkbox input[type="checkbox"]:focus + label::before {
    outline: 0;
}

.option-text {
    color: #00467d;
    font-size: 1.2rem;
    letter-spacing: 1px;
    margin-left: 1rem;
}
.info-text {
    color: #00467d;
    font-family: 'RobotoBold';
    text-align: center;
}
.submit-container {
    text-align: center;
    margin-top: 2rem;
}
.loader {
    margin: 1rem auto;
}
.submit {
    width: 80%;
    font-family: 'RobotoBold';
    letter-spacing: 2px;
    margin: 1rem auto;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    background: #00467d;
    border: 2px solid #00467d;
    color: #fff;
    transition: all 0.25s ease-in-out;
}
.submit:hover  {
    background-color: #00467d;
}
.submit:active {
    transform: scale(0.9)
}

.merged-message {
    margin: 1rem 0;
    padding: 1rem;
    border: 1px dashed #fff;
}
@media screen and (min-width: 700px) {
    .checkbox {
        margin: 0 auto;
    }
    .form-container {
        margin: 3rem auto 5%;
    }
    .info-message p {
        text-align: justify;
    }
    .form-title,
    .form-subtitle {
        text-align: center;
    }
    .checkbox label {
        padding-left: 7%;
    }
    .submit {
        margin: 4rem auto 0;
    }

}

@media screen and (min-width: 900px) {
    .form-container {
        margin: 3rem auto 5%;
    }
    .form {
        width: 90%;
        margin: 0 auto;
        padding: 3rem;
    }
    .form-title {
        font-size: 2rem;
    }
    .form-subtitle {
        font-size: 1.1rem;
    }
    .checkbox label {
        padding-left: 10%;
    }
    .submit {
        font-size: 1.2rem;
        max-width: 300px;
        padding: 0.7rem 0;
    }
}
</style>
