<template>
    <div v-if="currentCustomer">
        <div class="route">
            <top-bar />
            <div class="form-container">
                <form class="form" novalidate @submit.prevent="submitDetails">
                    <h1 v-if="editProfileMode" class="form-title">{{ $t('Details.headerEdit') }}</h1>
                    <h1 v-else class="form-title">{{ $t('Details.headerSignIn') }}</h1>
                    <div v-if="editProfileMode" class="form-subtitle">
                        {{ $t('Details.subHeaderEdit') }}
                    </div>
                    <div v-else class="form-subtitle">
                        {{ $t('Details.subHeaderSignIn') }}
                    </div>
                    <div class="form-input">
                        <input
                            v-model.trim="firstName"
                            required
                            :class="{'error-highlighted-input': showFirstNameError}"
                            @input="formatAndCheckFirstName"
                        >
                        <label
                            class="input-label"
                            :class="{'error-highlighted-label': showFirstNameError}"
                        >
                            {{ $t('Details.name') }}
                        </label>
                    </div>
                    <div class="form-input">
                        <input
                            v-model.trim="lastName"
                            required
                            :class="{'error-highlighted-input': showLastNameError}"
                            @input="formatAndCheckLastName"
                        >
                        <label
                            class="input-label"
                            :class="{'error-highlighted-label': showLastNameError}"
                        >
                            {{ $t('Details.surname') }}
                        </label>
                    </div>
                    <div class="form-input">
                        <input
                            v-model.trim="email"
                            required
                            :class="{'error-highlighted-input': showEmailError}"
                            @input="checkEmail"
                        >
                        <label
                            class="input-label"
                            :class="{'error-highlighted-label': showEmailError}"
                        >
                            {{ $t('Details.email') }}
                        </label>
                    </div>
                    <div class="error-message-wrapper">
                        <small v-if="formIsWrong" class="info-text error-highlighted-label">{{ $t('Details.pleaseFillTHeInputs') }}</small>
                        <small v-if="invalidCharacters" class="info-text error-highlighted-label">{{ $t('Details.invalidCharacters') }}</small>
                    </div>
                    <small class="info-text">{{ $t('Details.requiredFields') }}</small>
                    <small class="info-text">{{ $t('Details.emailSendLink') }}</small>
                    <div class="submit-container">
                        <pulse-loader v-if="loadingCompleted" color="#00467d" size="1rem" class="loader" />
                        <button v-else type="submit" class="submit">{{ editProfileMode ? $t('Details.next') : $t('ScanCard.submit') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import customerService from '@/services/customer-service'
    import TopBar from '@/components/TopBar'
    import greekUtils from 'greek-utils'

    export default {
        name: 'CustomerDetailsRoute',
        components: { TopBar },
        data() {
            return {
                firstName: null,
                lastName: null,
                email: null,
                editProfileMode: false,
                loadingCompleted: false,
                showFirstNameError: false,
                showLastNameError: false,
                showEmailError: false,
                formIsWrong: false,
                invalidCharacters: false
            }
        },
        computed: {
            currentCustomer() {
                return this.$store.getters.getCurrentCustomer
            }
        },
        created() {
            if (this.currentCustomer.verifiedCustomer) {
                this.editProfileMode = true
            }
            if (this.currentCustomer.firstName) {
                this.firstName = this.currentCustomer.firstName.toUpperCase()
            }
            if (this.currentCustomer.lastName) {
                this.lastName = this.currentCustomer.lastName.toUpperCase()
            }
            if (this.currentCustomer.emailAddress) {
                this.email = this.currentCustomer.emailAddress
            }
        },
        methods: {
            async submitDetails() {
                if (!this.isFormValid()) {
                    this.formIsWrong = true
                    setTimeout(() => { this.formIsWrong = false }, 5000)
                    return
                }
                this.loadingCompleted = true
                try {
                    await customerService.updateCustomerInfo(this.firstName, this.lastName, this.email)
                } catch {
                    this.loadingCompleted = false
                    this.makeToast(this.$t('Details.tryLaterError'))
                    return
                }
                try {
                    const mergedCards = await customerService.mergeByMobile()
                    this.$store.dispatch('setMergedCards', mergedCards)
                } catch {
                    this.loadingCompleted = false
                    this.makeToast(this.$t('Details.errorMerge'))
                    return
                }
                this.$store.dispatch('refreshCurrentCustomer').then(() => {
                    if (this.editProfileMode) {
                        this.loadingCompleted = false
                        this.$router.push('/phone-input?existingCustomer=true')
                        return
                    }
                    this.loadingCompleted = false
                    this.$router.push('/gdpr')
                })
            },
            isExistingCustomer() {
                return this.currentCustomer.verifiedCustomer
            },
            isFormValid() {
                if (!this.firstName || !this.lastName) {
                    return false
                }
                if (this.showFirstNameError || this.showLastNameError || this.showEmailError) {
                    return false
                }
                return true
            },
            formatAndCheckFirstName() {
                this.firstName = this.firstName.toUpperCase()
                this.firstName = greekUtils.sanitizeDiacritics(this.firstName)
                this.showFirstNameError = !this.hasOnlyLetters(this.firstName) || !this.isTextInputLengthMoreThan(this.firstName, 3)
            },
            formatAndCheckLastName() {
                this.lastName = this.lastName.toUpperCase()
                this.lastName = greekUtils.sanitizeDiacritics(this.lastName)
                this.showLastNameError = !this.hasOnlyLetters(this.lastName) || !this.isTextInputLengthMoreThan(this.lastName, 3)
            },
            hasOnlyLetters(input) {
                const regex = new RegExp('[^A-ZΑ-Ω ]')
                this.invalidCharacters = regex.test(String(input))

                return !regex.test(String(input))
            },
            isTextInputLengthMoreThan(input, length) {
                const regex = new RegExp(`[A-ZΑ-Ω ]{${length},}`)
                return regex.test(String(input))
            },
            checkEmail() {
                if (this.email === '') {
                    this.email = null
                }
                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (this.email) {
                    this.showEmailError = !regex.test(String(this.email).toLowerCase())
                } else {
                    this.showEmailError = false
                }
                return !regex.test(String(this.email).toLowerCase())
            }
        }
    }
</script>

<style scoped>
.route {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #1e5f93 0%, #011f4c 100%);
}

.form-container {
    position: relative;
    margin: 2% auto 5%;
    width: 90%;
    max-width: 750px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 5px 5px 15px 5px #011f4c;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    position: relative;
}
.form-title {
    color: #00467d;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 0;
}
.form-subtitle {
    color: #00467d;
    font-size: 0.9rem;
}
.form-input {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.input-label {
    color: #00467d;
    font-family: 'RobotoBold';
    font-size: 1rem;
    position: absolute;
    top: -0.5rem;
    left: 0;
    padding: 0.75rem 0;
    pointer-events: none;
    transition: all 0.25s ease-in-out;
}
.form-input input:focus ~ .input-label,
.form-input input:valid ~ .input-label {
    font-family: 'RobotoRegular';
    top: -1.5rem;
    left: 0;
    font-size: 0.8rem;
}
.form-input input {
    width: 100%;
    padding: 0.25rem 0.25rem 0;
    font-size: 1.2rem;
    border: none;
    border-bottom: 1px solid #00467d;
    margin-bottom: 1.75rem;
    color: #00467d;
    font-family: 'RobotoBold';
    background: transparent;
    outline: none;
}
.error-highlighted-input{
    border-bottom: 1px solid #dd1122 !important;
}
.error-highlighted-label,
.error-highlighted-input {
    color: #dd1122 !important;
}
.error-message-wrapper {
    text-align: center;
}
.info-text {
    color: #00467d;
    font-family: 'RobotoBold';
    text-align: center;
}
.submit-container {
    text-align: center;
    margin-top: 1rem;
}
.loader {
    margin: 1rem auto;
}
.submit {
    width: 90%;
    font-family: 'RobotoBold';
    letter-spacing: 1px;
    max-width: 250px;
    padding: 0.5rem 0;
    margin: 1rem auto;
    text-align: center;
    border-radius: 3px;
    border: none;
    transition: all 0.15s ease-in-out;
    background-color: #00467d;
    color: #fff;
}
.submit:hover {
    background-color: #025799;
}
.submit:active{
    transform: scale(0.95);
}
@media screen and (min-width: 700px) {
    .form-container {
        margin: 3rem auto 5%;
    }
    .form-title,
    .form-subtitle {
        text-align: center;
    }

}

@media screen and (min-width: 900px) {
    .form-container {
        margin: 3rem auto 5%;
    }
    .form {
        width: 90%;
        margin: 0 auto;
        padding: 3rem;
    }
    .form-title {
        font-size: 2rem;
    }
    .form-subtitle {
        font-size: 1.1rem;
    }
    .form-input input {
        font-size: 1.4rem;
    }
    .input-label {
        font-size: 1.4rem;
    }
    .form-input input:focus ~ .input-label,
    .form-input input:valid ~ .input-label {
        top: -1.6rem;
        font-size: 0.85rem;
    }
    .submit {
        font-size: 1.2rem;
        max-width: 300px;
        padding: 0.7rem 0;
    }
}
</style>
