<template>
    <div class="route">
        <top-bar />
        <div class="form-container">
            <form class="form" novalidate @submit.prevent="submitOtp">
                <h1 class="form-title">{{ $t('OtpRoute.header') }}</h1>
                <div class="form-subtitle">
                    {{ $t('OtpRoute.subHeader') }}
                </div>
                <span class="countdown-container">
                    {{ $t('OtpRoute.otpExpiresIn') }} <div class="countdown">{{ getRemainingFormattedTime }}</div>
                </span>
                <div class="form-input">
                    <input
                        ref="otpCode"
                        v-model.trim="otpCode"
                        required
                        @input="limitLengthToEightDigits"
                    >
                    <label class="input-label">{{ $t('OtpRoute.Otp') }}</label>
                </div>
                <div class="otp-btns">
                    <div
                        v-if="otpTimer.getRemainingSeconds() <= 0"
                        class="otp-clear"
                        @click.prevent="resendCode"
                    >
                        {{ $t('OtpRoute.resend') }}
                    </div>
                    <div
                        class="otp-clear"
                        @click.prevent="clearOtpCode"
                    >
                        {{ $t('OtpRoute.clear') }}
                    </div>
                </div>
                <button
                    type="submit"
                    class="submit"
                    :class="{ 'disabled-submit' : !otpCompleted}"
                >
                    {{ $t('OtpRoute.submit') }}
                </button>
            </form>
        </div>

    </div>
</template>

<script>

    import otpService from '@/services/otp-service'
    import customerService from '@/services/customer-service'
    import TopBar from '@/components/TopBar'
    import moment from 'moment'
    import Timer from '@/utils/timer'
    import limitLength from '@/utils/limit-length-util'

    export default {
        name: 'OtpEmRoute',
        components: { TopBar },
        data() {
            return {
                otpCode: null,
                otpCompleted: false,
                otpTimer: null
            }
        },
        computed: {
            getRemainingFormattedTime() {
                return `${this.otpTimer.getMinutes()} : ${this.otpTimer.getSeconds()}`
            },
            otpExpirationTime() {
                return this.$store.getters.getOtpExpirationTime
            }
        },
        watch: {
            otpCode: {
                immediate: true,
                handler(newVal) {
                    if (newVal && newVal.length >= 8) {
                        this.otpCompleted = true
                    } else {
                        this.otpCompleted = false
                    }
                }
            }
        },
        created() {
            this.otpTimer = new Timer(this.calculateRemainingSeconds())
            this.otpTimer.start()
        },
        mounted() {
            this.$refs.otpCode.focus()
        },
        methods: {
            calculateRemainingSeconds() {
                const otpExpirationEpoch = moment(this.otpExpirationTime).valueOf()
                const nowEpoch = moment().utc().valueOf()
                return parseInt((otpExpirationEpoch - nowEpoch) / 1000)
            },
            clearOtpCode() {
                this.otpCode = ''
            },
            resendCode() {
                this.clearOtpCode()
                this.otpTimer.stop()

                const encryptedBarcode = this.$store.getters.getEncryptedBarcode
                const phoneNumber = this.$store.getters.getPhoneNumber
                otpService.sendOtp(encryptedBarcode, phoneNumber)
                    .then((res) => {
                        this.$store.dispatch('setOtpExpirationTime', res.instant)
                        this.otpTimer = new Timer(this.calculateRemainingSeconds())
                        this.otpTimer.start()
                    })
                    .catch(() => { this.makeToast(this.$t('OtpRoute.resendError')) }
                    )
            },
            limitLengthToEightDigits() {
                this.otpCode = limitLength.limitInputLength(this.otpCode, 8)
            },
            async submitOtp() {
                const username = this.$store.getters.getBarcode
                customerService.loginCustomer(username, this.otpCode).then((resp) => {
                    // localStorage.setItem('api-token', resp.token)
                    this.$store.dispatch('login', resp.token)
                    this.$store.dispatch('removeOtpExpirationTime')
                    this.$router.push('/em-find-merge')
                }).catch(() => { this.makeToast(this.$t('OtpRoute.notValidOtp')) })
            }
        }
    }
</script>

<style scoped>
.route {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #1e5f93 0%, #011f4c 100%);
}
.form-container {
    position: relative;
    margin: 2% auto 5%;
    width: 90%;
    max-width: 750px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 5px 5px 15px 5px #011f4c;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    position: relative;
}
.form-title {
    color: #00467d;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 0;
}
.form-subtitle,
.countdown-container {
    color: #00467d;
    font-size: 0.9rem;
}
.countdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    font-weight: bold;
    color: #00467d;
}
.countdown {
    font-size: 1.3rem;
    margin-left: 0.5rem;
}

.form-input {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.input-label {
    color: #00467d;
    font-family: 'RobotoBold';
    font-size: 1rem;
    position: absolute;
    top: -0.5rem;
    left: 0;
    padding: 0.75rem 0;
    pointer-events: none;
    transition: all 0.25s ease-in-out;
}
.form-input input:focus ~ .input-label,
.form-input input:valid ~ .input-label {
    font-family: 'RobotoRegular';
    top: -1.5rem;
    left: 0;
    font-size: 0.8rem;
}
.form-input input {
    width: 100%;
    padding: 0.25rem 0.25rem 0;
    font-size: 1.2rem;
    border: none;
    border-bottom: 1px solid #00467d;
    margin-bottom: 1.75rem;
    color: #00467d;
    font-family: 'RobotoBold';
    background: transparent;
    outline: none;
}

.disabled-input {
    color: #00467d;
    letter-spacing: 1px;
    position: absolute;
    left: 0;
    top: -26px;
    padding: 0.25rem 0;
    font-size: 14px;
    font-family: 'RobotoRegular';
}

.form-input input:disabled {
    background-color: #00467d;
    color: #ffffff;
}

.otp-btns {
    margin: 1.5rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.otp-clear {
    font-family: 'RobotoRegular';
    padding: 0.5rem 1rem;
    border-radius: 3px;
    color: #00467d;
    background-color: #d1ecf1;
    border: none;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
}

.otp-clear + .otp-clear {
    margin-left: 1rem;
}

.submit {
    width: 90%;
    font-family: 'RobotoBold';
    letter-spacing: 1px;
    max-width: 250px;
    padding: 0.5rem 0;
    text-align: center;
    border-radius: 3px;
    border: none;
    transition: all 0.15s ease-in-out;
    margin: 3rem auto 0;
    background-color: #00467d;
    color: #fff;
}

.submit:hover {
    background-color: #025799;
}
.submit:active,
.otp-clear:active {
      transform: scale(0.95);
}
.disabled-submit {
    background-color: rgba(0, 71, 125, 0.75);
    color: rgba(255, 255, 255, 0.75);
    pointer-events: none;
}
@media screen and (min-width: 700px) {
    .form-container {
        margin: 3rem auto 5%;
    }
    .form-title,
    .form-subtitle {
        text-align: center;
    }
    .checkbox label {
        padding-left: 7%;
    }
    .submit {
        margin: 3rem auto 0;
    }

}

@media screen and (min-width: 900px) {
    .form-container {
        margin: 3rem auto 5%;
    }
    .form {
        width: 90%;
        margin: 0 auto;
        padding: 3rem;
    }
    .form-title {
        font-size: 2rem;
    }
    .form-subtitle {
        font-size: 1.1rem;
    }
    .form-input input {
        font-size: 1.4rem;
    }
    .input-label {
        font-size: 1.4rem;
    }
    .form-input input:focus ~ .input-label,
    .form-input input:valid ~ .input-label {
        top: -1.6rem;
        font-size: 0.85rem;
    }
    .checkbox label {
        padding-left: 6%;
    }
    .submit {
        font-size: 1.2rem;
        max-width: 300px;
        padding: 0.7rem 0;
    }
}
</style>
