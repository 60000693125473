import restAPI from '@/utils/rest-api'
export default {
    findMergeByMobile() {
        return restAPI({
            url: '/customers/ellinika-market/find-merge-by-mobile',
            method: 'post'
        })
    },
    updateCustomerInfo(name, lastname, email, encryptedBarcode) {
        return restAPI({
            url: '/customers/ellinika-market/update-customer-info',
            method: 'post',
            data: { name, lastname, email, encryptedBarcode }
        })
    },
    mergeByCard(encryptedBarcode) {
        return restAPI({
            url: '/customers/ellinika-market/merge-by-card',
            method: 'post',
            data: { encryptedBarcode }
        })
    },
    updateGdpr(gdprPreferences, encryptedBarcode) {
        return restAPI({
            url: '/customers/ellinika-market/update-gdpr',
            method: 'post',
            data: { gdprPreferences, encryptedBarcode }
        })
    }
}
