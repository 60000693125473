<template>
    <div>
        <div class="route">
            <top-bar />
            <div class="form-container">
                <form class="form" novalidate @submit.prevent="submitCard">
                    <h1 class="form-title">{{ $t('ScanCard.header') }}</h1>
                    <div class="form-subtitle">
                        {{ $t('ScanCard.cardNumber') }}
                    </div>
                    <div class="form-input">
                        <input
                            v-model.trim="cardNumber"
                            pattern="[0-9]*"
                            inputmode="numeric"
                            required
                            @input="limitLengthToThirteenDigits"
                        >
                        <label class="input-label">{{ $t('ScanCard.inputLabel') }}</label>
                    </div>
                    <div class="checkbox" @click="gdpr = !gdpr">
                        <input
                            id="gdpr"
                            v-model="gdpr"
                            type="checkbox"
                            value="true"
                        >
                        <label>
                            <span>{{ $t('ScanCard.gdpr[0]') }} </span>
                            <a
                                class="highlighted"
                                href="https://www.kritikos-sm.gr/pages/oroi-xrhshs"
                                target="_blank"
                            >{{ $t('ScanCard.gdpr[1]') }} </a>
                            <span>{{ $t('ScanCard.gdpr[2]') }} </span>
                            <a
                                class="highlighted"
                                href="https://www.kritikos-sm.gr/pages/prosopika-dedomena"
                                target="_blank"
                            >{{ $t('ScanCard.gdpr[3]') }}</a>
                        </label>
                    </div>
                    <div class="submit-container">
                        <pulse-loader v-if="loadingCompleted" color="#00467d" size="1rem" class="loader" />
                        <button v-else type="submit" class="submit">{{ $t('ScanCard.submit') }}</button>
                    </div>
                    <!-- <div class="scan-card-btn">
                        <img src="@/assets/scan_card.png" alt="" width="30" class="mr-2"> {{ $t('ScanCard.scanCard') }}
                        <image-barcode-reader
                            @decode="onDecode"
                            @error="onError"
                        />
                    </div> -->
                    <small v-if="errorScanCard" class="error-scan">Το σκανάρισμα της κάρτας απέτυχε. Παρακαλώ προσπαθήστε ξανά</small>
                </form>
            </div>
        </div>
        <custom-modal
            :open="isModalVisible"
            @close="closeModal"
        >
            <template #body>
                <div v-if="popupErrorMessage">
                    <p>{{ popupErrorMessage }}</p>
                </div>
            </template>
        </custom-modal>
    </div>
</template>

<script>
    import isValidEan13 from '@/utils/validateBarcode'
    import TopBar from '@/components/TopBar'
    import cardService from '@/services/card-service'
    import otpService from '@/services/otp-service'
    import CustomModal from '@/components/CustomModal'
    import dateTimeUtils from '@/utils/date-time-utils'
    import limitLength from '@/utils/limit-length-util'

    export default {
        name: 'ScanCardRoute',
        components: { CustomModal, TopBar },
        data() {
            return {
                cardNumber: '',
                cardResponse: null,
                gdpr: false,
                isModalVisible: false,
                popupErrorMessage: null,
                errorScanCard: false,
                loadingCompleted: false
            }
        },
        created() {
            this.$store.dispatch('logout')
        },
        methods: {
            showModal() {
                this.isModalVisible = true
            },
            closeModal() {
                this.loadingCompleted = false
                this.isModalVisible = false
            },
            async submitCard() {
                if (!this.validateForm()) {
                    return
                }
                this.loadingCompleted = true
                try {
                    this.cardResponse = await cardService.findByCard(this.cardNumber)
                } catch (error) {
                    this.makeToast(this.$t('ScanCard.somethingWentWrongError'))
                    this.loadingCompleted = false
                    return
                }
                if (this.cardResponse.status === 'MERGED' && this.cardResponse.obfuscatedParentCard) {
                    this.popupErrorMessage = this.$t('ScanCard.mergedCardError', { obfuscatedCard: this.cardResponse.obfuscatedParentCard, mergedOnDate: dateTimeUtils.getHumanizedDateTime(this.cardResponse.mergedOnDate) })
                    this.showModal()
                    return
                } else if (this.cardResponse.status === 'MERGED') {
                    this.popupErrorMessage = this.$t('ScanCard.unknownMergedCardError')
                    this.showModal()
                    return
                } else if (!['ACTIVE', 'NOT_ACTIVE_YET'].includes(this.cardResponse.status)) {
                    this.popupErrorMessage = this.$t('ScanCard.disabledCardError')
                    this.showModal()
                    return
                }
                this.$store.dispatch(
                    'setBarcodePair',
                    { barcode: this.cardNumber, encryptedBarcode: this.cardResponse.encryptedBarcode }
                )
                if (!this.cardResponse.verifiedMobile) {
                    this.$router.push({ path: '/phone-input' })
                } else {
                    otpService.sendOtp(this.cardResponse.encryptedBarcode, null).then((res) => {
                        this.$store.dispatch('setOtpExpirationTime', res.instant)
                        this.$router.push({ path: '/otp' })
                    }).catch((err) => {
                        this.loadingCompleted = false
                        this.makeToast(err.message)
                    })
                }
            },
            validateForm() {
                if (!isValidEan13(this.cardNumber)) {
                    this.makeToast(this.$t('ScanCard.wrongNumberError'))
                    return false
                }
                if (!this.gdpr) {
                    this.makeToast(this.$t('ScanCard.acceptGdprError'))
                    return false
                }
                return true
            },
            limitLengthToThirteenDigits() {
                this.cardNumber = limitLength.limitInputLength(this.cardNumber, 13)
            }
        }
    }
</script>

<style scoped>
.route {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #1e5f93 0%, #011f4c 100%);
}

.form-container {
    position: relative;
    margin: 2% auto 1rem;
    width: 90%;
    max-width: 750px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 5px 5px 15px 5px #011f4c;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    position: relative;
}
.form-title {
    color: #00467d;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 0;
}
.form-subtitle {
    color: #00467d;
    font-size: 0.9rem;
}
.form-input {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.input-label {
    color: #00467d;
    font-family: 'RobotoBold';
    font-size: 1rem;
    position: absolute;
    top: -0.5rem;
    left: 0;
    padding: 0.75rem 0;
    pointer-events: none;
    transition: all 0.25s ease-in-out;
}
.form-input input:focus ~ .input-label,
.form-input input:valid ~ .input-label {
    font-family: 'RobotoRegular';
    top: -1.5rem;
    left: 0;
    font-size: 0.8rem;
}
.form-input input {
    width: 100%;
    padding: 0.25rem 0.25rem 0;
    font-size: 1.2rem;
    border: none;
    border-bottom: 1px solid #00467d;
    margin-bottom: 1.75rem;
    color: #00467d;
    font-family: 'RobotoBold';
    background: transparent;
    outline: none;
}

.checkbox {
    width: 100%;
    margin: 1% auto 0;
    position: relative;
    display: block;
}

.checkbox input[type="checkbox"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;
}

.checkbox label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 20px;
    height: 20px;
    transition: transform 0.28s ease;
    border: 1px solid #00467d;
    box-shadow: 3px 3px 0px -1px #00467d;
}
.checkbox label:after {
    content: '';
    display: block;
    width: 10px;
    height: 5px;
    border-bottom: 2px solid #00467d;
    border-left: 2px solid #00467d;
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.25s;
    will-change: transform;
    position: absolute;
    top: 10px;
    left: 10px;
}
.checkbox input[type="checkbox"]:checked ~ label::before {
    color: #00467d;
}

.checkbox input[type="checkbox"]:checked ~ label::after {
    transform: rotate(-45deg) scale(1);
}

.checkbox label {
    position: relative;
    display: block;
    padding-left: 12%;
    margin-bottom: 0;
    font-weight: bold;
    cursor: pointer;
}
.checkbox label span {
    font-size: 0.75rem;
    color: #00467d;
}
.highlighted {
    font-size: 0.75rem;
    color: #ed1c24;
}

.checkbox input[type="checkbox"]:focus + label::before {
    outline: 0;
}
.submit-container {
    text-align: center;
    margin-top: 1rem;
}
.loader {
    margin: 1rem auto;
}
.submit,
.scan-card-btn {
    width: 90%;
    font-family: 'RobotoBold';
    letter-spacing: 1px;
    max-width: 250px;
    padding: 0.5rem 0;
    margin: 1rem auto;
    text-align: center;
    border-radius: 3px;
    border: none;
    transition: all 0.15s ease-in-out;
}
.error-scan {
    margin-top: 1rem;
    font-family: 'RobotoBold';
    text-align: center;
    color: #dd1122;
}

.submit {
    background-color: #00467d;
    color: #fff;
}
.scan-card-btn {
    position: relative;
    margin: 1rem auto 0;
    color: #00467d;
    background-color: #d1ecf1;
    cursor: pointer;
}
.scan-card-btn input {
    position: absolute;
    left: 0;
    opacity: 0;
}
.submit:hover {
    background-color: #025799;
}

.submit:active,
.scan-card-btn:active {
    transform: scale(0.95);
}

@media screen and (min-width: 700px) {
    .keimeno-logo {
        font-size: 2rem;
    }
    .checkbox {
        margin: 0 auto;
    }
    .form-container {
        margin: 3rem auto 5%;
    }
    .form-title,
    .form-subtitle {
        text-align: center;
    }
    .checkbox label {
        padding-left: 7%;
    }

}

@media screen and (min-width: 900px) {
    .form-container {
        margin: 3rem auto 5%;
    }
    .form {
        width: 90%;
        margin: 0 auto;
        padding: 3rem;
    }
    .form-title {
        font-size: 2rem;
    }
    .form-subtitle {
        font-size: 1.1rem;
    }
    .form-input input {
        font-size: 1.4rem;
    }
    .input-label {
        font-size: 1.4rem;
    }
    .form-input input:focus ~ .input-label,
    .form-input input:valid ~ .input-label {
        top: -1.6rem;
        font-size: 0.85rem;
    }
    .checkbox label {
        padding-left: 6%;
    }
    .submit {
        font-size: 1.2rem;
        max-width: 300px;
        padding: 0.7rem 0;
    }
    .scan-card-btn {
        display: none;
    }
}

</style>
