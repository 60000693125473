import restAPI from '@/utils/rest-api'
export default {
    validateEmailToken(token) {
        return restAPI({
            url: '/emails/validate-email-token',
            method: 'post',
            data: { content: token }
        })
    }
}
