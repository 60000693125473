export default {
    methods: {
        makeToast(message, variant = 'danger') {
            this.$bvToast.toast(message, {
                variant: variant,
                noCloseButton: true,
                autoHideDelay: 4000,
                solid: true,
                appendToast: true
            })
        }
    }
}
