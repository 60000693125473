import jwtDecode from 'jwt-decode'
import moment from 'moment'
export default {
    isJwtExpired(jwtToken) {
        const decodedJwtToken = jwtDecode(jwtToken)
        const jwtTokenExpirationTime = decodedJwtToken.exp
        return moment().unix() > jwtTokenExpirationTime
    },
    getPrivileges(jwtToken) {
        const decodedJwtToken = jwtDecode(jwtToken)
        return decodedJwtToken.scopes || []
    },
    isHmac512(jwtToken) {
        const headers = jwtDecode(jwtToken, { header: true })
        return headers.alg === 'HS512'
    },
    isValid(jwtToken) {
        if (!jwtToken) {
            return false
        }
        return !this.isJwtExpired(jwtToken) && this.isHmac512(jwtToken)
    },
    parseToken(jwtToken) {
        return jwtDecode(jwtToken)
    }
}
