import axios from 'axios'
import jwtUtils from '@/utils/jwt-utils'

const service = axios.create({
    baseURL: `${process.env.VUE_APP_HOST_DOMAIN}${process.env.VUE_APP_BASE_API}`,
    timeout: 2 * 60 * 1000
})

service.interceptors.request.use(
    config => {
        let token = window.localStorage.getItem('api-token')
        if (!jwtUtils.isValid(token)) {
            localStorage.removeItem('api-token')
            token = null
        }
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        return Promise.reject(error)
    }
)

export default service
