<template>
    <div class="route">
        <top-bar />
        <div class="form-container">
            <form class="form" novalidate @submit.prevent="sendOtp">
                <h1 v-if="currentCustomer" class="form-title">{{ $t('PhoneInputRoute.editPhoneNumber') }}</h1>
                <h1 v-else class="form-title">{{ $t('PhoneInputRoute.insertPhoneNumber') }}</h1>
                <div v-if="currentCustomer" class="form-subtitle">
                    {{ $t('PhoneInputRoute.editPhoneNumberSubtitle') }}
                </div>
                <div v-else class="form-subtitle">
                    {{ $t('PhoneInputRoute.insertPhoneNumberSubtitle') }}
                </div>
                <div class="form-input">
                    <input v-model="cardNumber" disabled>
                    <label class="disabled-label">{{ $t('PhoneInputRoute.cardNumber') }}</label>
                </div>
                <div class="form-input">
                    <input
                        v-model.trim="phoneNumber"
                        :class="{'error-highlighted-input': !isPhoneNumberCorrect}"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        max-length="10"
                        required
                        @input="validatePhone()"
                    >
                    <label
                        class="input-label"
                        :class="{'error-highlighted-label': !isPhoneNumberCorrect}"
                    >
                        {{ $t('PhoneInputRoute.mobile') }}
                    </label>
                    <small
                        v-if=" !phoneNumber && !isPhoneNumberCorrect"
                        class="note error-highlighted-label"
                    >
                        {{ $t('PhoneInputRoute.requiredPhone') }}
                    </small>
                    <small
                        v-if=" phoneNumber && !isPhoneNumberCorrect"
                        class="note error-highlighted-label"
                    >
                        {{ $t('PhoneInputRoute.errorInputPhone') }}
                    </small>
                    <small class="note">{{ $t('PhoneInputRoute.note') }}</small>
                </div>
                <div class="submit-container">
                    <pulse-loader v-if="loadingCompleted" color="#00467d" size="1rem" class="loader" />
                    <button
                        v-else
                        type="submit"
                        class="submit"
                        :class="{ 'disabled-submit' : !isPhoneNumberCorrect}"
                    >
                        {{ phoneNumberHasNotChanged() ? $t('PhoneInputRoute.next') : $t('PhoneInputRoute.sendSms') }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import otpService from '@/services/otp-service'
    import TopBar from '@/components/TopBar'
    import limitLength from '@/utils/limit-length-util'
    export default {
        name: 'PhoneInputRoute',
        components: { TopBar },
        data() {
            return {
                cardNumber: '',
                encryptedBarcode: '',
                phoneNumber: '',
                isPhoneNumberCorrect: true,
                loadingCompleted: false
            }
        },
        computed: {
            currentCustomer() {
                return this.$store.getters.getCurrentCustomer
            }
        },
        async created() {
            this.cardNumber = this.$store.getters.getBarcode
            this.encryptedBarcode = this.$store.getters.getEncryptedBarcode
            if (this.$route.query.existingCustomer) {
                this.phoneNumber = this.currentCustomer.mobileNumber.slice(3)
            }
        },
        methods: {
            sendOtp() {
                if (this.phoneNumberHasNotChanged()) {
                    this.$router.push('/gdpr?existingCustomer=true')
                    return
                }
                this.validatePhone()
                if (this.isPhoneNumberCorrect) {
                    this.loadingCompleted = true
                    otpService.sendOtp(this.encryptedBarcode, this.phoneNumber
                    ).then((res) => {
                        this.$store.dispatch('setPhoneNumber', this.phoneNumber)
                        this.$store.dispatch('setOtpExpirationTime', res.instant)
                        if (this.currentCustomer) {
                            this.$router.push('/otp?existingCustomer=true')
                            return
                        }
                        this.loadingCompleted = false
                        this.$router.push('/otp')
                    }).catch(() => {
                        this.makeToast('Παρακαλώ ξαναπροσπαθήστε')
                    })
                } else {
                    this.makeToast('Ο αριθμός που συμπληρώσατε δεν είναι έγκυρος')
                }
            },
            validatePhone() {
                this.limitLengthToTenDigits()
                this.isPhoneNumberCorrect = /^[6]{1}[9]{1}[0-9]{8}$/.test(this.phoneNumber)
            },
            phoneNumberHasNotChanged() {
                if (this.currentCustomer) {
                    return this.phoneNumber === this.currentCustomer.mobileNumber.slice(3)
                }
                return false
            },
            limitLengthToTenDigits() {
                this.phoneNumber = limitLength.limitInputLength(this.phoneNumber, 10)
            }
        }
    }
</script>

<style scoped>
.route {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #1e5f93 0%, #011f4c 100%);
}

.form-container {
    position: relative;
    margin: 2% auto 5%;
    width: 90%;
    max-width: 750px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 5px 5px 15px 5px #011f4c;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    position: relative;
}
.form-title {
    color: #00467d;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 0.1rem;
}
.form-subtitle {
    color: #00467d;
    font-size: 0.9rem;
}
.form-input {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.input-label {
    color: #00467d;
    font-family: 'RobotoBold';
    font-size: 1rem;
    position: absolute;
    top: -0.5rem;
    left: 0;
    padding: 0.75rem 0;
    pointer-events: none;
    transition: all 0.25s ease-in-out;
}
.form-input + .form-input {
    margin-top: 0.5rem;
}
.disabled-label {
    color: #00467d;
    font-family: 'RobotoRegular';
    letter-spacing: 1px;
    position: absolute;
    left: 0;
    top: -1.6rem;
    padding: 0.25rem 0;
    font-size: 0.8rem;
}
.form-input input:focus ~ .input-label,
.form-input input:valid ~ .input-label {
    font-family: 'RobotoRegular';
    top: -1.5rem;
    left: 0;
    font-size: 0.8rem;
}
.form-input input {
    width: 100%;
    padding: 0.25rem 0.25rem 0;
    font-size: 1.2rem;
    border: none;
    border-bottom: 1px solid #00467d;
    margin-bottom: 1.75rem;
    color: #00467d;
    font-family: 'RobotoBold';
    background: transparent;
    outline: none;
}
.form-input input:disabled {
    background-color: #d1ecf1;
    color: #00467d;
}
.error-highlighted-input{
    border-bottom: 1px solid #dd1122 !important;
}
.error-highlighted-label,
.error-highlighted-input {
    color: #dd1122 !important;
}
.submit-container {
    text-align: center;
    margin-top: 1rem;
}
.loader {
    margin: 1rem auto;
}
.submit {
    width: 90%;
    font-family: 'RobotoBold';
    letter-spacing: 1px;
    max-width: 250px;
    padding: 0.5rem 0;
    text-align: center;
    border-radius: 3px;
    border: none;
    transition: all 0.15s ease-in-out;
    margin: 1rem auto;
    background-color: #00467d;
    color: #fff;
}

.submit:hover {
    background-color: #025799;
}
.submit:active{
    transform: scale(0.95);
}
.note {
    color: #00467d;
    font-family: 'RobotoBold';
    text-align: center;
}
.disabled-submit {
    background-color: rgba(0, 71, 125, 0.75);
    color: rgba(255, 255, 255, 0.75);
    pointer-events: none;
}

@media screen and (min-width: 700px) {
    .form-container {
        margin: 3rem auto 5%;
    }
    .form-title,
    .form-subtitle {
        text-align: center;
    }
    .submit {
        margin: 4rem auto 0;
    }
}
@media screen and (min-width: 900px) {
    .form-container {
        margin: 3rem auto 5%;
    }
    .form {
        width: 90%;
        margin: 0 auto;
        padding: 3rem;
    }
    .form-title {
        font-size: 2rem;
        margin-bottom: 0;
    }
    .form-subtitle {
        font-size: 1.1rem;
    }
    .form-input input {
        font-size: 1.4rem;
    }
    .input-label {
        font-size: 1.4rem;
    }
    .form-input input:focus ~ .input-label,
    .form-input input:valid ~ .input-label {
        top: -1.6rem;
        font-size: 0.85rem;
    }
    .submit {
        font-size: 1.2rem;
        max-width: 300px;
        padding: 0.7rem 0;
    }
}
</style>
