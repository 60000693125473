import restAPI from '@/utils/rest-api'
export default {
    sendOtp(encryptedBarcode, mobile) {
        return restAPI({
            url: '/otp',
            method: 'post',
            data: { encryptedBarcode, mobile }
        })
    },
    verifyOtp(otpCode) {
        return restAPI({
            url: `/customers/current/verify-otp/${otpCode}`,
            method: 'post'
        })
    }
}
