import moment from 'moment'
import 'moment-timezone'
export default {
    getHumanizedDate(timestamp) {
        return moment.utc(timestamp).format('DD/MM/YYYY')
    },
    getHumanizedDateTime(timestamp) {
        return moment(timestamp).tz('Europe/Athens').format('DD/MM/YYYY H:mm')
    }
}
