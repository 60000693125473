<template>
    <div class="route">
        <top-bar />
        <div class="form-container">
            <div
                v-if="isLoading"
                class="message-container"
            >
                <p class="message">
                    {{ $t('EmailVerificationRoute.pleaseWait') }}
                </p>
                <pulse-loader color="#00467d" size="1rem" class="loader" />
            </div>
            <div v-else class="message-container">
                <p class="message">
                    {{ message.text }}
                </p>
                <router-link v-if="message.success" to="/gdpr?existingCustomer=true" class="action-button">
                    {{ $t('EmailVerificationRoute.continue') }}
                </router-link>
                <a v-else href="https://www.kritikos-sm.gr/" class="action-button">
                    {{ $t('EmailVerificationRoute.close') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import emailService from '@/services/email-service'
    import customerService from '@/services/customer-service'
    import TopBar from '@/components/TopBar'

    export default {
        name: 'EmailVerificationRoute',
        components: { TopBar },
        data() {
            return {
                message: {},
                isLoading: true
            }
        },
        computed: {
            mergedCards() {
                return this.$store.getters.getObfuscatedMergedCards
            }
        },
        async created() {
            const emailToken = this.$route.query.token

            const response = await this.emailTokenLogin(emailToken)
            if (!response) {
                return
            }
            this.$store.dispatch('login', response.token)

            const updatedEmail = await this.updateCustomerEmail(emailToken)
            if (!updatedEmail) {
                return
            }

            const mergedEmailResponse = await this.mergeByEmailAndStore()
            if (!mergedEmailResponse) {
                return
            }

            this.isLoading = false
            this.message = { success: true, text: this.$t('EmailVerificationRoute.successfulMessage') }
        },
        methods: {
            async emailTokenLogin(emailToken) {
                try {
                    return await emailService.validateEmailToken(emailToken)
                } catch (error) {
                    this.checkErrorResponse(error.response.status)
                    this.isLoading = false
                    return null
                }
            },
            async updateCustomerEmail(emailToken) {
                try {
                    await customerService.updateCustomerEmail(emailToken)
                    return true
                } catch (error) {
                    this.checkErrorResponse(error.response.status)
                    this.isLoading = false
                    return null
                }
            },
            async mergeByEmailAndStore() {
                try {
                    const mergedCardsResponse = await customerService.mergeByEmail()
                    this.$store.dispatch('setMergedCards', mergedCardsResponse)
                    return mergedCardsResponse
                } catch (error) {
                    this.checkErrorResponse(error.response.status)
                    this.isLoading = false
                    return null
                }
            },
            checkErrorResponse(errorStatus) {
                if (errorStatus === 400) {
                    this.message = { success: false, text: this.$t('EmailVerificationRoute.tokenNotValid') }
                } else {
                    this.message = { success: false, text: this.$t('EmailVerificationRoute.tryLater') }
                }
            }

        }
    }
</script>

<style scoped>
.route {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #1e5f93 0%, #011f4c 100%);
}

.form-container {
    position: relative;
    margin: 2% auto 1rem;
    width: 90%;
    max-width: 750px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 5px 5px 15px 5px #011f4c;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message {
    font-family: 'RobotoBold';
    text-align: center;
    font-size: 1.25rem;
    color: #00467d;
    margin-bottom: 1.5rem;
}
.action-button {
    width: 90%;
    font-family: 'RobotoBold';
    letter-spacing: 1px;
    max-width: 250px;
    padding: 0.5rem 1rem;
    text-align: center;
    border-radius: 3px;
    border: none;
    transition: all 0.15s ease-in-out;
    margin: 1rem auto ;
    background-color: #00467d;
    color: #fff;
    text-decoration: none;
}

.action-button:hover {
    background-color: #025799;
}

.action-button:active {
    transform: scale(0.95);
}
@media screen and (min-width: 700px) {
    .form-container {
        margin: 3rem auto 1rem;
    }
    .message {
        font-size: 1.6rem;
    }
}

</style>
