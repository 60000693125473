<template>
    <div
        v-if="open"
        class="custom-modal-backdrop"
    >
        <transition name="modal" appear>
            <div v-if="open" class="custom-modal">
                <header class="custom-modal-header">
                    <div class="top-triangle" />
                    <button
                        type="button"
                        class="btn-close"
                        @click="close"
                    >
                        Χ
                    </button>
                </header>
                <section class="custom-modal-body">
                    <slot name="body" />
                </section>
                <footer class="custom-modal-footer">
                    <div class="ok" @click="close">OK</div>
                    <div class="bottom-triangle" />
                </footer>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'CustomModal',
        props: {
            type: {
                type: String,
                required: false,
                default: 'info'
            },
            open: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            close() {
                this.$emit('close')
            }
        }
    }
</script>

<style scoped>
.modal-enter {
    opacity: 0;
    transform: translateY(-30px);
}
.modal-enter-to{
    opacity: 1;
    transform: translateY(0)
}

.modal-enter-active {
    transition: all 0.5s ease-out;
}

.custom-modal-backdrop {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-modal {
    width: 90%;
    max-width: 600px;
    background-color: #1e5f93;
    box-shadow: 2px 2px 20px 1px #000;
    display: flex;
    flex-direction: column;
}

.custom-modal-header,
.custom-modal-footer {
    display: flex;
    position: relative;
}

.custom-modal-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.75rem;
}

.top-triangle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background: #011f4c;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
}
.bottom-triangle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 100px;
    background: #011f4c;
    clip-path: polygon(0 100%, 100% 100%, 100% 0);
}

.custom-modal-footer {
    padding: 1rem;
}

.custom-modal-body {
    margin-top: 1rem;
    padding: 2rem;
    text-align: center;
    font-weight: 600;
    color: #ffffff;
}

.btn-close {
    font-size: 1.5rem;
    cursor: pointer;
    font-family: 'RobotoBold';
    color: #ce1e24;
    border: none;
    padding: 0.2rem 0.8rem;
    background: transparent;
}

.submit, .ok {
    text-align: center;
    width: 50%;
    font-family: 'RobotoBold';
    letter-spacing: 2px;
    margin: 0 auto;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    background: #011f4c;
    border: none;
    color: #fff;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
}
.submit:hover,
.submit:focus  {
    background-color: #00467d;
    color: #fff;
}
.submit:active {
    transform: scale(0.9)
}
@media screen and (min-width: 500px) {
    .submit {
        max-width: 250px;
        padding: 0.75rem 2.5rem;
    }
    .ok {
        max-width: 150px;
        padding: 0.75rem 2.5rem;
    }
}
</style>
