import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import Vuex from 'vuex'
import store from '@/store'
import { BootstrapVue } from 'bootstrap-vue'
import VueI18n from 'vue-i18n'
import el from '@/locale/el.json'
import PulseLoader from 'vue-spinner/src/PulseLoader'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import toaster from '@/mixins/toaster-mixin'

Vue.use(BootstrapVue)
Vue.use(VueI18n)
Vue.use(Vuex)

Vue.component('pulse-loader', PulseLoader)

Vue.mixin(toaster)

const messages = { el }
const i18n = new VueI18n({
    locale: 'el',
    messages
})

Vue.config.productionTip = false

new Vue({
    el: '#app',
    name: 'Main',
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')
